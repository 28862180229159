import React, { useContext, useEffect, useState } from 'react';
import styles from './SetParameterValues.component.module.scss';
import { SetParameterValuesOperationSnackbar } from './components/SetParameterValuesOperationSnackbar';
import { useSelector } from 'react-redux';
import { devicesSelectors, locationSelectors } from '../../../store/selectors';
import _ from 'lodash';
import { PrivateRoute, Role } from '@indigo-cloud/common-react';
import { AppRouteNames, appRoutes, pages } from '../../../App.router';
import { AwsExportsContext } from '../../../components';
import { useHistory } from 'react-router-dom';

export interface SetParameterValuesProperties {
	agentEndpointId: string;
}

export const SetParameterValues: React.FC<SetParameterValuesProperties> = ({agentEndpointId}) => {
	const setDeviceParameterValuesOperation = useSelector(devicesSelectors.setDeviceParameterValuesOperation(agentEndpointId));

	const history = useHistory();
	const didOperationSucceed = setDeviceParameterValuesOperation?.didSucceed === true;

	const previousRoute = useSelector(locationSelectors.prevRoute);
	const didNavigateFromDeviceInfoPage = previousRoute?.location?.pathname === appRoutes.DeviceInfo;

	const contextAwsExports = useContext(AwsExportsContext);

	const onModalBack = () => {
		if (didNavigateFromDeviceInfoPage) {
			history.goBack();
		} else {
			// New tab loaded the child/next route directly and has no history, or the user directly changed the address bar to the nested route from another page. Both cases require push instead of goBack
			history.push(appRoutes.DeviceInfo.replace(':agentEndpointId', agentEndpointId));
		}
	}

	useEffect(() => {
		if (didOperationSucceed === true) {
			onModalBack();
		}
	}, [
		didOperationSucceed
	]);

	return (<div className={styles.container}>
		<SetParameterValuesOperationSnackbar agentEndpointId={agentEndpointId} />

		<PrivateRoute roles={[ Role.Device, Role.DeviceDevelopmentDevices, Role.DeviceTrialist, Role.Admin]} path={appRoutes.DeviceSetParameterValues} exact component={pages[AppRouteNames.DeviceSetParameterValues]} awsExports={contextAwsExports} />
	</div>);
};
