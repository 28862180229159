import {call, delay, put, select, takeEvery} from '@redux-saga/core/effects';
import { authActions, deviceGroupsActions} from '../actions';
import { ADD_DYNAMIC_DEVICE_GROUP, DELETE_DEVICE_GROUP, EXECUTE_DEVICE_GROUP_COMMAND, GET_DEVICE_GROUP_PARAMETERS,
	LOAD_DEVICE_GROUPS,
	LOAD_DEVICE_GROUPS_INFO,
	LOAD_DEVICE_GROUP_DEVICES,
	LOAD_DEVICE_GROUP_JOBS,
	SET_DEVICE_GROUP_PARAMETERS,
	SET_DEVICE_GROUP_POLICY,
	TRIGGER_DEVICE_GROUP_ADD_DEVICES,
	TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE,
	TRIGGER_DEVICE_GROUP_REMOVE_DEVICES,
	UPDATE_DYNAMIC_DEVICE_GROUP
} from '../constants';
import { DeviceGroup, Job, Thing, awsExports } from '../../shared';
import { PaginatedResults, processUnauthenticatedResponse, request } from '@indigo-cloud/common-react';
import { deviceGroupsSelectors } from '../selectors';
import { AxiosError  } from 'axios';
import moment from 'moment';
import { push } from 'connected-react-router';
import { appRoutes } from '../../components';

const basePath = '/v1/deviceGroups';

function* loadDeviceGroups() {
	try {
		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				options: {
					queryStringParameters: {}
				},
				path: basePath
			}
		);
		yield put(deviceGroupsActions.loadDeviceGroupsSuccess(response));
	} catch (error) {
		console.error('An error occurred while loading the device groups.', error);
		yield put(deviceGroupsActions.loadDeviceGroupsError(error));
	}
}

function* loadDeviceGroupInfo(action: ReturnType<typeof deviceGroupsActions.loadDeviceGroupInfo>) {
	const { payload: { name } } = action;
	try {
		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				options: {
					queryStringParameters: {}
				},
				path: `${basePath}/${name}`
			}
		);
		yield put(deviceGroupsActions.loadDeviceGroupInfoSuccess(name, response));
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while loading the device info with id '${name}'`, error);
		yield put(deviceGroupsActions.loadDeviceGroupInfoError(name, error));
	}
}

function* loadDeviceGroupJobs(action: ReturnType<typeof deviceGroupsActions['loadDeviceGroupJobs']>) {
	const {
		payload: {filters, name, limit = 10, cursor, pageIndex = 0, collectAllData = false}
	} = action;
	try {
		console.log('filters', filters)

		const keysFilters = Object.keys(filters || {});
		let search = '';
		if (keysFilters?.length) {
			search = keysFilters.reduce((previous, keyFilter) => {
				const filter = filters![keyFilter] as string;
				previous += `&${encodeURIComponent(`search[${keyFilter}]`)}=${encodeURIComponent(filter)}`;

				return previous;
			}, '');

		}

		let requestUrl = `${basePath}/${name}/jobs?limit=${limit}${cursor?.length ? `&cursor=${cursor}` : ''}${Object.keys(filters || '').length > 0 ? search : ''}`
		const isoFormat = 'YYYY-MM-DDTHH:mm:ss';

		let responses: Record<string, PaginatedResults<Job>> = {};
		let counterIndex = 0;
		while(requestUrl !== ''){
			const response = yield call(
				request,
				{
					apiName: 'usp',
					awsExports,
					path: requestUrl
				}
			);
			
			if (Array.isArray(response.data)) {
			
				response.data.forEach((job: any) => {
					const startedAtDate = moment(job.startedAt, isoFormat).toDate();
					const updatedAtDate = moment(job.updatedAt, isoFormat).toDate();
					job.startedAt = moment(startedAtDate).format('DD/MM/YYYY HH:mm:ssA');
					job.updatedAt = moment(updatedAtDate).format('DD/MM/YYYY HH:mm:ssA');
				});
	
				response.data = response.data.sort((a: {startedAt: string}, b: {startedAt: string}) => {
					const startedAtDate = moment(a.startedAt, 'DD/MM/YYYY HH:mm:ssA');
					const startedAtDateB = moment(b.startedAt, 'DD/MM/YYYY HH:mm:ssA');
					
					if (startedAtDate.isBefore(startedAtDateB)) {
						return 1;
					  }
					  if (startedAtDateB.isBefore(startedAtDate)) {
						return -1;
					  }
					  return 0;
	
				});
			}

			responses = {...responses, [`${pageIndex + counterIndex++}_${JSON.stringify(filters)}`]: response};
			if(!collectAllData) break;
			requestUrl = (response.pagination.cursor) ? `${basePath}/${encodeURIComponent(name)}/jobs?limit=${limit}&cursor=${response.pagination.next}` : '';
		}
		yield put(deviceGroupsActions.loadDeviceGroupJobsSuccess(name, responses));
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		yield put(deviceGroupsActions.loadDeviceGroupJobsError(error));
	}
}


function* loadDeviceGroupDevices(action: ReturnType<typeof deviceGroupsActions.loadDeviceGroupDevices>) {
	const { payload: {pageIndexPrefix = 'page', limit = 250, cursor, pageIndex = 0, name, collectAllData = false} } = action;
	try {
		let requestUrl = `${basePath}/${encodeURIComponent(name)}/devices?limit=${limit}${cursor?.length ? `&cursor=${cursor}` : ''}`
		let responses: Record<string, PaginatedResults<Thing>> = {};
		let counterIndex = 0;
		while(requestUrl !== ''){
			const response = yield call(
				request,
				{
					apiName: 'usp',
					awsExports,
					options: {
						queryStringParameters: {}
					},
					path: requestUrl
				}
			);
			responses = {...responses, [`${pageIndexPrefix}_${pageIndex + counterIndex++}`]: response};
			if(!collectAllData) break;
			requestUrl = (response.pagination.cursor) ? `${basePath}/${encodeURIComponent(name)}/devices?limit=${limit}&cursor=${response.pagination.next}` : '';
		}
		yield put(deviceGroupsActions.loadDeviceGroupDevicesSuccess(responses, name));
	} catch (error) {
		console.error('An error occurred while loading the devices for a device group.', error);
		yield put(deviceGroupsActions.loadDeviceGroupDevicesError(error, name));
	}
}

function* triggerDeviceGroupFirmwareUpdate(action: ReturnType<typeof deviceGroupsActions.triggerDeviceGroupFirmwareUpdate>) {

	const { payload: { deviceGroup, firmwareName, formikPromise, shouldForceDowngrade, activationWindows, abortConfig, executionsRolloutConfig, schedulingConfig } } = action;

	try {
		const response = yield call(
			request,
			{
				apiName: 'rom',
				awsExports,
				method: 'post',
				options: {
					body: {
						abortConfig,
						activationWindow: activationWindows,
						executionsRolloutConfig,
						firmwareName,
						forceDowngrade: shouldForceDowngrade,
						
						
						
						schedulingConfig
					}
				},
				path: `${basePath}/${encodeURIComponent(deviceGroup)}/triggerFirmwareUpdate`
			}
		);

		

		yield put(deviceGroupsActions.triggerDeviceGroupFirmwareUpdateSuccess(response, deviceGroup));

		formikPromise.resolve(response);

	} catch (error) {
		const errorAxios = (error as AxiosError);
		let errorMessage = '';
		errorMessage = errorAxios.isAxiosError ? (typeof errorAxios?.response?.data === 'string' ? errorAxios?.response?.data :  errorAxios?.response?.data.message) : error?.message;

		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while triggering the firmware update for device group '${deviceGroup}'`, errorMessage);
		console.log('[error]', errorMessage);
		yield put(deviceGroupsActions.triggerDeviceGroupFirmwareUpdateError({
			message: errorMessage
		} as any, deviceGroup));
		formikPromise.reject(error);
	}
}


function* deleteDeviceGroupSaga(action: ReturnType<typeof deviceGroupsActions['deleteDeviceGroup']>) {
	const {
		payload: {deviceGroupId}
	} = action;
	try {

		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				method: 'del',
				path: `${basePath}/${encodeURIComponent(deviceGroupId)}`
			}
		);

		yield put(deviceGroupsActions.deleteDeviceGroupSuccess(deviceGroupId, response));
		yield put(deviceGroupsActions.loadDeviceGroups());
		yield put(push(appRoutes.DeviceGroup));
	} catch (error) {
		const errorAxios = (error as AxiosError);
		let errorMessage = '';
		errorMessage = errorAxios.isAxiosError ? (typeof errorAxios?.response?.data === 'string' ? errorAxios?.response?.data :  errorAxios?.response?.data.message) : error?.message;
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error('An error occurred while deleting the deviceGroup', errorMessage, deviceGroupId);
		yield put(deviceGroupsActions.deleteDeviceGroupError({
			message: errorMessage
		} as any, deviceGroupId));
	}
}

function* triggerDeviceGroupAddDevices(action: ReturnType<typeof deviceGroupsActions.triggerDeviceGroupAddDevices>) {
	const { payload: { deviceGroup, devices, formikPromise } } = action;

	try {

		
		const batchSize = 4000;
		const timeout = 10_000;

		const numberBatches = Math.ceil(devices.thingNames.length / batchSize);

		for (let index = 0; index < numberBatches; index++) {
			const start = index * batchSize;
			const end = (index + 1) * batchSize;
			
			const batch = devices.thingNames.slice(start, end);
			try {
				yield call(
					request,
					{
						apiName: 'usp',
						awsExports,
						method: 'post',
						options: {
							body: {
								thingNames: batch,
								operationSettings: { 
									timeout: timeout
								}
							}
						},
						path: `${basePath}/${encodeURIComponent(deviceGroup)}/devices`
					}
				);
			} catch (error) {
				console.error('An error occurred while processing the current batch', error)
			}
			
		}

		yield put(deviceGroupsActions.triggerDeviceGroupAddDevicesSuccess({}, deviceGroup));
		

		formikPromise.resolve({});
	} catch (error) {
		const errorAxios = (error as AxiosError);
		let errorMessage = '';
		errorMessage = errorAxios.isAxiosError ? errorAxios?.response?.data : error.message;
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while triggering to add devices to the device group '${deviceGroup}'`, errorMessage);
		console.log('[error]', errorMessage);
		yield put(deviceGroupsActions.triggerDeviceGroupAddDevicesError({
			message: errorMessage
		} as any, deviceGroup));
		formikPromise.reject(error);
	}
}

function* triggerDeviceGroupRemoveDevices(action: ReturnType<typeof deviceGroupsActions.triggerDeviceGroupRemoveDevices>) {
	const { payload: { deviceGroup, devices, formikPromise } } = action;

	try {

		let response;
		const batchSize = 1200;

		const numberBatches = Math.ceil(devices.thingNames.length / batchSize);

		for (let index = 0; index < numberBatches; index++) {
			const start = index * batchSize;
			const end = (index + 1) * batchSize;
			
			const batch = devices.thingNames.slice(start, end);

			try {
				response = yield call(
					request,
					{
						apiName: 'usp',
						awsExports,
						method: 'del',
						options: {
							body: {
								thingNames: batch
							}
						},
						path: `${basePath}/${encodeURIComponent(deviceGroup)}/devices`
					}
				);
			} catch (error) {
				console.error('An error occurred while processing the current batch', error);
			}
		}

		yield put(deviceGroupsActions.triggerDeviceGroupRemoveDevicesSuccess(response, deviceGroup));

		formikPromise.resolve(response);
	} catch (error) {
		const errorAxios = (error as AxiosError);
		let errorMessage = '';
		errorMessage = errorAxios.isAxiosError ? errorAxios?.response?.data : error.message;
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while triggering to remove devices from the device group '${deviceGroup}'`, errorMessage);
		console.log('[error]', errorMessage);
		yield put(deviceGroupsActions.triggerDeviceGroupRemoveDevicesError({
			message: errorMessage
		} as any, deviceGroup));
		formikPromise.reject(error);
	}
}

function* addDynamicDeviceGroup(action: ReturnType<typeof deviceGroupsActions.addDynamicGroup>) {

	const { payload: { formikPromise, model } } = action;
	try {

		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				method: 'post',
				options: {
					body: model
				},
				path: `${basePath}`
			}
		);

		yield put(deviceGroupsActions.addDynamicGroupSuccess(response.data));
		formikPromise.resolve(response);

		yield put(deviceGroupsActions.loadDeviceGroups());
	} catch (error) {
		const errorAxios = (error as AxiosError);
		let errorMessage = '';
		errorMessage = errorAxios.isAxiosError ? (typeof errorAxios?.response?.data === 'string' ? errorAxios?.response?.data :  errorAxios?.response?.data.message) : error?.message;
		
		if(errorAxios?.response?.status === 400 && errorMessage) {
			const errorMessageArray = errorMessage.split('|');
			errorMessage = errorMessageArray.length > 1 ? errorMessageArray.slice(1).join(' ') : errorMessage;
		}

		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error('An error occurred while creating a dynamic device group.', error);
		yield put(deviceGroupsActions.addDynamicGroupError({
			message: errorMessage
		} as any));
		formikPromise.reject(error);
	}
}

function* updateDynamicDeviceGroup(action: ReturnType<typeof deviceGroupsActions.updateDynamicGroup>) {

	const { payload: { formikPromise, model } } = action;
	try {

		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				method: 'put',
				options: {
					body: model
				},
				path: `${basePath}/${model.name}`
			}
		);

		yield put(deviceGroupsActions.updateDynamicGroupSuccess(response.data));
		yield put(deviceGroupsActions.loadDeviceGroupInfo(model.name));
		formikPromise.resolve(response);
	} catch (error) {
		const errorAxios = (error as AxiosError);
		let errorMessage = '';
		errorMessage = errorAxios.isAxiosError ? (typeof errorAxios?.response?.data === 'string' ? errorAxios?.response?.data :  errorAxios?.response?.data.message) : error?.message;
		
		if(errorAxios?.response?.status === 400 && errorMessage) {
			const errorMessageArray = errorMessage.split('|');
			errorMessage = errorMessageArray.length > 1 ? errorMessageArray.slice(1).join(' ') : errorMessage;
		}

		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error('An error occurred while updating a dynamic device group.', error);
		yield put(deviceGroupsActions.updateDynamicGroupError({
			message: errorMessage
		} as any));
		formikPromise.reject(error);
	}
}

function* getDeviceGroupParametersSaga(action: ReturnType<typeof deviceGroupsActions.getDeviceGroupParameters>) {

	const { payload: { deviceGroupId, formikPromise, abortConfig, executionsRolloutConfig, schedulingConfig, getParameterValues } } = action;
	try {
		const parameters = getParameterValues.parameters.map((parameter: string ) => `paramPath=${encodeURIComponent(parameter)}`).join('&');
		const response = yield call(
			request,
			{
				apiName: 'rom',
				awsExports,
				method: 'post',
				options: {
					body: {
						abortConfig,
						executionsRolloutConfig,
						schedulingConfig
					}
				},
				path: `${basePath}/${encodeURIComponent(deviceGroupId)}/getServiceElements?${parameters}`
			}
		);
		yield put(deviceGroupsActions.getDeviceGroupParametersSuccess(response, deviceGroupId));
		formikPromise.resolve(response);
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while executing the device group command with device group id '${deviceGroupId}'`, error);
		yield put(deviceGroupsActions.executeDeviceGroupCommandError({
			message: error.message
		} as any, deviceGroupId));
		formikPromise.reject(error);
	}
}

function* setDeviceGroupParametersSaga(action: ReturnType<typeof deviceGroupsActions.setDeviceGroupParameters>) {

	const { payload: { deviceGroupId, formikPromise, activationWindows, abortConfig, executionsRolloutConfig, schedulingConfig, setParameterValues: { allowPartial, parameters  } } } = action;
	try {

		const response = yield call(
			request,
			{
				apiName: 'rom',
				awsExports,
				method: 'post',
				options: {
					body: {
						abortConfig,
						executionsRolloutConfig,
						schedulingConfig,
						'setRequestBody': {
							allowPartial,
							'updateObjs': parameters?.map(({ key, value, required }) => {
								const parameterPath = key?.split('.');
								const parameter = parameterPath.pop();
								return {
									'objPath': `${parameterPath.join('.')}.`,
									'paramSettings': [{
										param: parameter,
										required,
										value
									}]
								}
							})
						}
					}
				},
				path: `${basePath}/${encodeURIComponent(deviceGroupId)}/setServiceElements`
			}
		);
		yield put(deviceGroupsActions.setDeviceGroupParametersSuccess(response, deviceGroupId));
		formikPromise.resolve(response);
	} catch (error) {
		const errorAxios = (error as AxiosError);
		let errorMessage = '';
		errorMessage = errorAxios.isAxiosError ? (typeof errorAxios?.response?.data === 'string' ? errorAxios?.response?.data :  errorAxios?.response?.data.message) : error?.message;
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while executing the device group command with device group id '${deviceGroupId}'`, errorMessage);
		yield put(deviceGroupsActions.setDeviceGroupParametersError({
			message: errorMessage
		} as any, deviceGroupId));
		formikPromise.reject(error);
	}
}

function* setDeviceGroupPolicySaga(action: ReturnType<typeof deviceGroupsActions.setDeviceGroupPolicy>) {

	const { payload: { deviceGroupId, formikPromise, activationWindows, abortConfig, executionsRolloutConfig, schedulingConfig, parameter } } = action;
	try {

		const response = yield call(
			request,
			{
				apiName: 'rom',
				awsExports,
				method: 'post',
				options: {
					body: {
						abortConfig,
						executionsRolloutConfig,
						schedulingConfig,
						...parameter
					}
				},
				path: `${basePath}/${encodeURIComponent(deviceGroupId)}/triggerPolicyUpdate`
			}
		);
		yield put(deviceGroupsActions.setDeviceGroupPolicySuccess(response, deviceGroupId));
		formikPromise.resolve(response);
	} catch (error) {
		const errorAxios = (error as AxiosError);
		let errorMessage = '';
		errorMessage = errorAxios.isAxiosError ? (typeof errorAxios?.response?.data === 'string' ? errorAxios?.response?.data :  errorAxios?.response?.data.message) : error?.message;
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while executing the device group command with device group id '${deviceGroupId}'`, errorMessage);
		yield put(deviceGroupsActions.setDeviceGroupPolicyError({
			message: errorMessage
		} as any, deviceGroupId));
		formikPromise.reject(error);
	}
}

function* executeDeviceCommandSaga(action: ReturnType<typeof deviceGroupsActions.executeDeviceGroupCommand>) {

	const { payload: { deviceGroupId, formikPromise, activationWindows, abortConfig, executionsRolloutConfig, schedulingConfig, command } } = action;

	try {

		const response = yield call(
			request,
			{
				apiName: 'rom',
				awsExports,
				method: 'post',
				options: {
					body: {
						...command,
						abortConfig,
						executionsRolloutConfig,
						schedulingConfig
					}
				},
				path: `${basePath}/${encodeURIComponent(deviceGroupId)}/commands`
			}
		);

		yield put(deviceGroupsActions.executeDeviceGroupCommandSuccess(response, deviceGroupId));

		formikPromise.resolve(response);
	} catch (error) {
		const errorAxios = (error as AxiosError);
		let errorMessage = '';
		errorMessage = errorAxios.isAxiosError ? (typeof errorAxios?.response?.data === 'string' ? errorAxios?.response?.data :  errorAxios?.response?.data.message) : error?.message;
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while executing the device group command with device group id '${deviceGroupId}'`, errorMessage);
		yield put(deviceGroupsActions.executeDeviceGroupCommandError({
			message: errorMessage
		} as any, deviceGroupId));
		formikPromise.reject(error);
	}
}

export const deviceGroupsSagas = () => {

	function* watcher() {
		yield takeEvery(LOAD_DEVICE_GROUPS, loadDeviceGroups);
		yield takeEvery(LOAD_DEVICE_GROUPS_INFO, loadDeviceGroupInfo);
		yield takeEvery(TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE, triggerDeviceGroupFirmwareUpdate);
		yield takeEvery(TRIGGER_DEVICE_GROUP_ADD_DEVICES, triggerDeviceGroupAddDevices);
		yield takeEvery(TRIGGER_DEVICE_GROUP_REMOVE_DEVICES, triggerDeviceGroupRemoveDevices);
		yield takeEvery(LOAD_DEVICE_GROUP_JOBS, loadDeviceGroupJobs);
		yield takeEvery(LOAD_DEVICE_GROUP_DEVICES, loadDeviceGroupDevices);
		yield takeEvery(ADD_DYNAMIC_DEVICE_GROUP, addDynamicDeviceGroup);
		yield takeEvery(UPDATE_DYNAMIC_DEVICE_GROUP, updateDynamicDeviceGroup);
		yield takeEvery(DELETE_DEVICE_GROUP, deleteDeviceGroupSaga);
		yield takeEvery(EXECUTE_DEVICE_GROUP_COMMAND, executeDeviceCommandSaga);
		yield takeEvery(GET_DEVICE_GROUP_PARAMETERS, getDeviceGroupParametersSaga);
		yield takeEvery(SET_DEVICE_GROUP_PARAMETERS, setDeviceGroupParametersSaga);
		yield takeEvery(SET_DEVICE_GROUP_POLICY, setDeviceGroupPolicySaga);
	}

	return {
		loadDeviceGroupInfo,
		loadDeviceGroups,
		watcher
	};
};
