import React, { useState } from 'react';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';

import { Role, isAuthorised, useAuthorisation } from '@indigo-cloud/common-react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { appRoutes } from '../../../../App.router';
import { deviceGroupsSelectors } from '../../../../store/selectors';
import { DeviceGroupInfoModalType } from '../../DeviceGroupInfo.page';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import TouchAppIcon from '@mui/icons-material/TouchApp';

import styles from './DeviceGroupInfoActions.component.module.scss';

interface DeviceGroupInfoActionsProperties {
	deviceGroup: string;
	groupType: string;

}

export const DeviceGroupInfoActionsDataSelectors = {
	addDevice: 'DeviceGroupInfo-add-device',
	update: 'DeviceGroupInfo-update',
	execute: 'DeviceGroupInfo-execute',
	apvFab: 'DeviceInfo-apv-fab',
	dpvFab: 'DeviceInfo-dpv-fab',
	executeCommandsFab: 'DeviceInfo-execute-commands-fab',
	getParameterValues: 'DeviceInfo-get-parameter-values-fab',
	setParameterValues: 'DeviceInfo-set-parameter-values-fab',
	firmwareUpdateFab: 'DeviceInfo-firmware-update-fab',
	gpvFab: 'DeviceInfo-gpv-fab',
	speedDial: 'DeviceInfo-speed-dial',
	speedTestFab: 'DeviceInfo-speedtest-fab',
	spvFab: 'DeviceInfo-spv-fab',
	triggerPolicy: 'DeviceInfo-trigger-policy'
};

type DeviceModalType = 'gpv' | 'spv' | 'execute_commands' | undefined;

export const DeviceGroupInfoActions: React.FC<DeviceGroupInfoActionsProperties> = ({ deviceGroup , groupType}) => {

	const actions = [
		// TODO: Centralise firmware/device etc action icons
		{ dataSelector: DeviceGroupInfoActionsDataSelectors.firmwareUpdateFab, icon: <SettingsApplicationsIcon />, name: 'Firmware Update', roles: [ Role.DeviceGroup, Role.DeviceGroupAdmin,  Role.Admin], route: appRoutes.DeviceGroupFirmwareUpdate.replace('/:firmwareName?', ''), type: 'firmware_update' as DeviceGroupInfoModalType, hide: false, action: '' },
		{ dataSelector: DeviceGroupInfoActionsDataSelectors.addDevice, icon: <AddCircleOutlineIcon />, name: 'Add device to the Device Group', roles: [ Role.DeviceGroup, Role.DeviceGroupAdmin, Role.Admin], route: appRoutes.DeviceGroupEditDevices, type: 'add_device_to_device_group' as DeviceModalType, hide: groupType === 'DYNAMIC', action: 'addDevices' },
		{ dataSelector: DeviceGroupInfoActionsDataSelectors.update, icon: <AppRegistrationIcon />, name: 'Update Device Group', roles: [ Role.DeviceGroup, Role.DeviceGroupAdmin,  Role.Admin], route: appRoutes.DeviceGroupUpdate.replace(':id', deviceGroup), type: 'update' as DeviceModalType, action: '' },
		{ dataSelector: DeviceGroupInfoActionsDataSelectors.getParameterValues, icon: <SearchIcon />, name: 'Get Parameter Values', roles:[Role.DeviceGroup, Role.DeviceGroupAdmin, Role.Admin, Role.ReadOnly], route: appRoutes.DeviceGroupGetParameterValues.replace(':id', deviceGroup).replace('/:parameterPaths?', ''), type: 'gpv' as DeviceGroupInfoModalType, action: '' },
		{ dataSelector: DeviceGroupInfoActionsDataSelectors.setParameterValues, icon: <EditIcon />, name: 'Set Parameter Values', roles: [ Role.DeviceGroup, Role.DeviceGroupAdmin,  Role.Admin], route: appRoutes.DeviceGroupSetParameterValues.replace(':id', deviceGroup).replace('/:parameterPaths?', ''), type: 'spv' as DeviceGroupInfoModalType, action: '' },
		{ dataSelector: DeviceGroupInfoActionsDataSelectors.executeCommandsFab, icon: <PlayCircleFilledIcon />, name: 'Execute Commands', roles: [Role.DeviceGroup, Role.DeviceGroupAdmin, Role.Admin], route: appRoutes.DeviceGroupExecuteCommand.replace(':id', deviceGroup).replace('/:commands?', ''), type: 'execute_commands' as DeviceGroupInfoModalType, action: '' },
		{ dataSelector: DeviceGroupInfoActionsDataSelectors.triggerPolicy, icon: <TouchAppIcon />, name: 'Trigger Policy', roles: [Role.DeviceGroup, Role.DeviceGroupAdmin, Role.Admin], route: appRoutes.DeviceGroupTriggerPolicy.replace(':id', deviceGroup), type: 'trigger_policy' as DeviceGroupInfoModalType, action: '' }
	];

	const [open, setOpen] = useState(true);
	const history = useHistory();

	const deviceGroupInfo = useSelector(deviceGroupsSelectors.getDeviceGroupInfoOperation(deviceGroup))?.result;

	const userAuthorisationGroups = useAuthorisation();

	const toggleOpen = () => {
		open ? setOpen(false) : setOpen(true);
	};

	return (<SpeedDial
		ariaLabel="Device Group Info Actions"
		className={styles.speedDial}
		icon={<SpeedDialIcon />}
		data-cy={DeviceGroupInfoActionsDataSelectors.speedDial}
		onClick={toggleOpen}
		open={open}
		direction={'up'}
	 >
		{actions.filter(({roles, hide}) => isAuthorised(roles, userAuthorisationGroups) && !hide).map((action) => {
			return (
				<SpeedDialAction
				  key={action.type}
				  icon={action.icon}
					tooltipTitle={action.name}
					data-cy={action.dataSelector}
					onClick={() => {
						history.push({
							 pathname: `${action.route.replace(':id', encodeURIComponent(deviceGroup)).replace(':action', encodeURIComponent(action?.action))}`
						 })
					 }}
				/>
			 )
		})}
	 </SpeedDial>);
};
